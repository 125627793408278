import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { metaReducers, reducers } from '../../reducers';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SdProgressBarA11yComponent } from '../../shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';
import { TdSfAppComponent } from './td-sf-app.component';
import { SfCoreModule } from '../sf/core/sf-core.module';
import { TdSfAppRoutingModule } from './td-sf-app-routing.module';

@NgModule({ declarations: [TdSfAppComponent],
    bootstrap: [TdSfAppComponent], imports: [BrowserModule,
        TdSfAppRoutingModule,
        BrowserAnimationsModule,
        EffectsModule.forRoot([]),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        environment.reduxToolsEnabled
            ? StoreDevtoolsModule.instrument({
                maxAge: environment.reduxMaxAge,
                logOnly: environment.production,
                serialize: true,
                connectInZone: true
            })
            : [],
        SfCoreModule,
        SdProgressBarA11yComponent], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class TdSfAppModule {}
