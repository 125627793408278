import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { SidedrawerService } from '../../../sidedrawer/services/sidedrawer.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { localeDefaultSelector } from '../../../dictionary/store/dictionary.selectors';
import {catchError, exhaustMap, filter, take, tap} from 'rxjs/operators';
import { Locale } from '../../../dictionary/models/locale.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SfAuthActions } from '../core/store/sf-auth.store';
import { Observable, of } from 'rxjs';
import { SetActiveSideDrawer } from '../../../sidedrawer/store/sidedrawer.actions';
import { SideDrawer } from '../../../sidedrawer/models/side-drawer.model';

export const sfSideDrawerResolver: ResolveFn<SideDrawer> = (
  route,
  state,
  sideDrawerService = inject(SidedrawerService),
  store: Store<AppState> = inject(Store<AppState>)
): Observable<SideDrawer> => {
  const { sideDrawerId } = route.params;
  return store.select(localeDefaultSelector).pipe(
    filter(locale => locale !== null),
    take(1),
    exhaustMap(locale =>
      sideDrawerService
        .getSideDrawerHomeLite(sideDrawerId, Locale.getLocaleId(locale))
        .pipe(
          catchError((error: HttpErrorResponse) => {
            store.dispatch(
              SfAuthActions.generalErrorModeUpdated({
                generalErrorMode:
                  error.status === 404
                    ? '404'
                    : error.status === 403 || error.status === 401
                      ? '403'
                      : 'generic',
              })
            );
            return of(null);
          }),
          tap(sideDrawer =>
            store.dispatch(new SetActiveSideDrawer({ data: sideDrawer }))
          )
        )
    )
  );
};
