import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SfAppRoutingModule } from './sf-app-routing.module';
import { SfAppComponent } from './sf-app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { metaReducers, reducers } from '../../reducers';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SfCoreModule } from './core/sf-core.module';
import { SdProgressBarA11yComponent } from '../../shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';

@NgModule({ declarations: [SfAppComponent],
    bootstrap: [SfAppComponent], imports: [BrowserModule,
        SfAppRoutingModule,
        BrowserAnimationsModule,
        EffectsModule.forRoot([]),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        environment.reduxToolsEnabled
            ? StoreDevtoolsModule.instrument({
                maxAge: environment.reduxMaxAge,
                logOnly: environment.production,
                serialize: true,
                connectInZone: true
            })
            : [],
        SfCoreModule,
        SdProgressBarA11yComponent], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SfAppModule {}
