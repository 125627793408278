export const adobeTagEvents = new Map([
  [
    `/core/home/{{id}}/plans`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'info-requests',
      },
    },
  ],
  [
    `/core/home/{{id}}/plans/details/{{id}}`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'info-requests-details',
      },
    },
  ],
  [
    `"/core/home/{{id}}/my-sd"`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'records',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'records',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd/records/{{wildcard}}/list`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'records-list',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd/records/{{wildcard}}/view/{{id}}`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'records-detail',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd/records/{{wildcard}}/form/{{id}}/files`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'record-edit-files',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd/records/{{wildcard}}/form/{{id}}/general`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'record-edit-general-info',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd/records/{{wildcard}}/form/{{id}}/reminders`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'record-edit-reminders',
      },
    },
  ],
  [
    `/core/home/{{id}}/my-sd/records/{{wildcard}}/form/{{id}}/related`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'record-edit-linked-records',
      },
    },
  ],
  [
    `/core/home/{{id}}/reminders/all`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'reminders',
      },
    },
  ],
  [
    `/core/home/{{id}}/reminders/reminder`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'reminders-create-reminder',
      },
    },
  ],
  [
    `/core/home/{{id}}/timeline`,
    {
      eventType: 'pageChange',
      event: 'pageChange',
      page: {
        name: '/$$pageName$$', // host+pathname
        subsection: 'timeline',
      },
    },
  ],
]);
