import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { inject } from '@angular/core';
import { SfAuthActions } from './sf-auth.store';
import { Router } from '@angular/router';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { SfRoutesEnum } from '../../routes/sf-routes.enum';
import { ConfigService } from '../../../../core/services/config.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import {
  BrandingConfigLoaded,
  DefaultBrandingLoaded,
  GettingBrandingChange,
} from '../../../../core/store/core.actions';

export const generalErrorModeUpdated$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const router = inject(Router);
    return actions$.pipe(
      ofType(SfAuthActions.generalErrorModeUpdated),
      switchMap(({ generalErrorMode }) => {
        if (
          generalErrorMode === 'dictionary' ||
          generalErrorMode === 'generic'
        ) {
          return fromPromise(router.navigateByUrl(`/${SfRoutesEnum.error}`));
        }
        if (generalErrorMode === '404') {
          return fromPromise(router.navigateByUrl(`/${SfRoutesEnum.notFound}`));
        }
        if (generalErrorMode === '403') {
          return fromPromise(
            router.navigateByUrl(`/${SfRoutesEnum.forbidden}`)
          );
        }
        return EMPTY;
      })
    );
  },
  { functional: true, dispatch: false }
);

export const defaultBrandConfigurationRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const service = inject(ConfigService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(SfAuthActions.defaultBrandConfigurationRequested),
      tap(() =>
        store.dispatch(new GettingBrandingChange({ gettingBranding: true }))
      ),
      exhaustMap(({ brandCode }) =>
        service.getTenantConfiguration(brandCode).pipe(
          catchError(() => of(null)),
          map(response => {
            store.dispatch(
              new GettingBrandingChange({ gettingBranding: false })
            );
            if (response) {
              store.dispatch(new DefaultBrandingLoaded({ data: response }));
              return new BrandingConfigLoaded({ data: response });
            }
            return SfAuthActions.generalErrorModeUpdated({
              generalErrorMode: 'generic',
            });
          })
        )
      )
    );
  },
  { functional: true }
);
