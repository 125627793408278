import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
  TitleStrategy,
} from '@angular/router';
import { AuthRoutes } from '../../auth/routes/auth.routes';
import { CoreRoutes } from '../../core/routes/core.routes';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { DictionaryRoutesEnum } from '../../dictionary/routes/dictionary-routes.enum';
import { SdPageTitleStrategy } from '../../core/providers/sd-page-title-strategy.provider';
import { environment } from '../../../environments/environment';

const routes: Routes = [
  {
    path: AuthRoutes.root,
    loadChildren: () =>
      environment.keycloakDisabled
        ? import('../../auth/auth.module').then(m => m.AuthModule)
        : import('./keycloak-auth/keycloak-auth.module').then(
            m => m.KeycloakAuthModule
          ),
  },
  {
    path: CoreRoutes.root,
    loadChildren: () =>
      import('./core/td-core.module').then(m => m.TdCoreModule),
    canActivate: [AuthGuard],
  },
  {
    path: DictionaryRoutesEnum.root,
    loadChildren: () => import('../../dictionary/routes/dictionary.routes'),
  },
  {
    path: '**',
    redirectTo: 'auth/authorize',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: SdPageTitleStrategy,
    },
  ],
})
export class TdAppRoutingModule {}
