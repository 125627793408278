import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ClientNames } from './app/clients/client-names.enum';
import { SfAppModule } from './app/clients/sf/sf-app.module';
import { TdAppModule } from './app/clients/td/td-app.module';
import { TdSfAppModule } from './app/clients/td-sf/td-sf-app.module';
import { MobileAppModule } from './app/clients/mobile/mobile-app.module';

if (environment.production) {
  enableProdMode();
}

if (environment.appName === ClientNames.my) {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (environment.appName === ClientNames.sf) {
  platformBrowserDynamic()
    .bootstrapModule(SfAppModule)
    .catch(err => console.error(err));
}

if (environment.appName === ClientNames.tdSf) {
  platformBrowserDynamic()
    .bootstrapModule(TdSfAppModule)
    .catch(err => console.error(err));
}

if (environment.appName === ClientNames.td) {
  platformBrowserDynamic()
    .bootstrapModule(TdAppModule)
    .catch(err => console.error(err));
}
if (environment.appName === ClientNames.mobile) {
  platformBrowserDynamic()
    .bootstrapModule(MobileAppModule)
    .catch(err => console.error(err));
}
