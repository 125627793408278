import { SfAppComponent } from '../sf/sf-app.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-sd-progress-bar-a11y
      *ngIf="(loadingData$ | async) === true"
      progressBarType="main" />
    <div *ngIf="(loadingData$ | async) === false" class="td-sf-app-root">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .td-sf-app-root {
        background-color: var(--appBackground);
        height: 100%;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdSfAppComponent extends SfAppComponent {}
