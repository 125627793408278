import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from '../../reducers';
import { environment } from '../../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { TdAppComponent } from './td-app.component';
import { TdAppRoutingModule } from './td-app-routing.module';
import { KeycloakAuthModule } from './keycloak-auth/keycloak-auth.module';
import { AuthModule } from '../../auth/auth.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({ declarations: [TdAppComponent],
    bootstrap: [TdAppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        MatTabsModule,
        EffectsModule.forRoot([]),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        environment.reduxToolsEnabled
            ? StoreDevtoolsModule.instrument({
                maxAge: environment.reduxMaxAge,
                logOnly: environment.production,
                serialize: true,
                connectInZone: true
            })
            : [],
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        StoreRouterConnectingModule.forRoot(),
        TdAppRoutingModule,
        environment.keycloakDisabled ? AuthModule : KeycloakAuthModule.forRoot()], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class TdAppModule {}
