import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CoreRoutes } from '../../core/routes/core.routes';
import { SidedrawerRoutesEnum } from '../../sidedrawer/routes/sidedrawer-routes.enum';
import { HomeRoutes } from '../../home/routes/home.routes';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { PlansRoutes } from '../../plans/routes/plans.routes';
import { RemindersRoutes } from '../../reminders/routes/reminders.routes';
import { SfRoutesEnum } from '../sf/routes/sf-routes.enum';
import { sfSideDrawerResolver } from '../sf/resolvers/sf-side-drawer.resolver';
import { sfAccountResolver } from '../sf/resolvers/sf-account.resolver';

const routes: Routes = [
  {
    path: 'auth/authorize',
    loadComponent: () =>
      import(
        '../../clients/sf/auth/views/sf-authenticate-view/sf-authenticate-view.component'
      ).then(m => m.SfAuthenticateViewComponent),
  },
  {
    path: SfRoutesEnum.forbidden,
    loadComponent: () =>
      import(
        '../../clients/sf/core/views/sf-forbidden-view/sf-forbidden-view.component'
      ).then(m => m.SfForbiddenViewComponent),
  },
  {
    path: SfRoutesEnum.error,
    loadComponent: () =>
      import(
        '../../clients/sf/core/views/sf-generic-error-view/sf-generic-error-view.component'
      ).then(m => m.SfGenericErrorViewComponent),
  },
  {
    path: SfRoutesEnum.notFound,
    loadComponent: () =>
      import(
        '../../clients/sf/core/views/sf-not-found-view/sf-not-found-view.component'
      ).then(m => m.SfNotFoundViewComponent),
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${SidedrawerRoutesEnum.root}/records`,
    loadChildren: () =>
      import('./records/td-sf-records.module').then(m => m.TdSfRecordsModule),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${PlansRoutes.root}`,
    loadChildren: () =>
      import('../../clients/sf/plans/sf-plans.module').then(
        m => m.SfPlansModule
      ),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${RemindersRoutes.sfr}`,
    loadChildren: () =>
      import('../../clients/td-sf/reminders/td-sf-reminders.module').then(
        m => m.TdSfRemindersModule
      ),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: SfRoutesEnum.notFound,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class TdSfAppRoutingModule {}
