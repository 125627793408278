import { SfNetworksModule } from './networks/sf-networks.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SfRoutesEnum } from './routes/sf-routes.enum';
import { CoreRoutes } from '../../core/routes/core.routes';
import { SidedrawerRoutesEnum } from '../../sidedrawer/routes/sidedrawer-routes.enum';
import { HomeRoutes } from '../../home/routes/home.routes';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { sfSideDrawerResolver } from './resolvers/sf-side-drawer.resolver';
import { PlansRoutes } from '../../plans/routes/plans.routes';
import { RemindersRoutes } from '../../reminders/routes/reminders.routes';
import { sfAccountResolver } from './resolvers/sf-account.resolver';
import { NetworksRoutesEnum } from 'src/app/networks/routes/networks-routes.enum';

const routes: Routes = [
  {
    path: 'auth/authorize',
    loadComponent: () =>
      import(
        './auth/views/sf-authenticate-view/sf-authenticate-view.component'
      ).then(m => m.SfAuthenticateViewComponent),
  },
  {
    path: SfRoutesEnum.forbidden,
    loadComponent: () =>
      import('./core/views/sf-forbidden-view/sf-forbidden-view.component').then(
        m => m.SfForbiddenViewComponent
      ),
  },
  {
    path: SfRoutesEnum.error,
    loadComponent: () =>
      import(
        './core/views/sf-generic-error-view/sf-generic-error-view.component'
      ).then(m => m.SfGenericErrorViewComponent),
  },
  {
    path: SfRoutesEnum.notFound,
    loadComponent: () =>
      import('./core/views/sf-not-found-view/sf-not-found-view.component').then(
        m => m.SfNotFoundViewComponent
      ),
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${SidedrawerRoutesEnum.root}/records`,
    loadChildren: () =>
      import('./records/sf-records.module').then(m => m.SfRecordsModule),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${PlansRoutes.root}`,
    loadChildren: () =>
      import('./plans/sf-plans.module').then(m => m.SfPlansModule),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${RemindersRoutes.sfr}`,
    loadChildren: () =>
      import('./reminders/sf-reminders.module').then(m => m.SfRemindersModule),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: `${CoreRoutes.root}/${HomeRoutes.root}/:sideDrawerId/${NetworksRoutesEnum.root}`, // //':sdId/' + NetworksRoutesEnum.root,
    loadChildren: () =>
      import('./networks/sf-networks.module').then(m => m.SfNetworksModule),
    resolve: {
      sidedrawer: sfSideDrawerResolver,
      account: sfAccountResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: SfRoutesEnum.notFound,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class SfAppRoutingModule {}
