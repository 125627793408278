import { DestroyRef, Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WebMessageDataDto } from '../../models/web-message-data.dto';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { SfAuthActions } from '../store/sf-auth.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class SfWebMessagesService {
  data$ = fromEvent(window, 'message').pipe(
    takeUntilDestroyed(this.destroyRef),
    tap((event: MessageEvent) => {
      const { origin, data } = event;

      if (!origin) {
        // TODO Add conditions to filter unknown origins
        return;
      }
      if ((data as WebMessageDataDto)?.token) {
        console.log({ token: data.token });
        this.store.dispatch(SfAuthActions.tokenLoaded({ token: data.token }));
      }
      if ((data as WebMessageDataDto)?.localeId) {
        console.log({ localeId: data.localeId });
        this.store.dispatch(
          SfAuthActions.dictionaryRequested({ localeId: data.localeId })
        );
      }
      if ((data as WebMessageDataDto)?.brandCode) {
        console.log({ brandCode: data.brandCode });
        this.store.dispatch(
          SfAuthActions.defaultBrandConfigurationRequested({
            brandCode: data.brandCode,
          })
        );
      }
    })
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly destroyRef: DestroyRef
  ) {
    console.log('service created');
    this.data$.subscribe();
  }
}
