import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DictionaryLoaded,
  GettingDictionaryChanges,
  LanguagesLoaded,
  LocaleDefaultChange,
} from '../../../../dictionary/store/dictionary.actions';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { DictionaryHelper } from '../../../../dictionary/helper/dictionary.helper';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { DictionaryService } from '../../../../dictionary/services/dictionary.service';
import { SfAuthActions } from './sf-auth.store';
import { Language } from '../../../../dictionary/models/language.model';

export const requestDictionary$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store$ = inject(Store<AppState>);
    const service = inject(DictionaryService);
    return actions$.pipe(
      ofType(SfAuthActions.dictionaryRequested),
      tap(() =>
        store$.dispatch(
          new GettingDictionaryChanges({ gettingDictionary: true })
        )
      ),
      exhaustMap(({ localeId }) =>
        forkJoin([
          service.getDictionary(localeId).pipe(catchError(() => of(null))),
          service.getLanguages().pipe(catchError(() => of(null))),
        ]).pipe(
          map(([dictionary, languages]) => {
            store$.dispatch(
              new GettingDictionaryChanges({ gettingDictionary: false })
            );
            if (languages) {
              store$.dispatch(new LanguagesLoaded({ languages }));
              store$.dispatch(
                new LocaleDefaultChange({
                  data: Language.getLocaleByLocaleId(languages, localeId),
                })
              );
            }
            if (dictionary) {
              DictionaryHelper.loadDictionaryStore(dictionary, store$, false);
              return new DictionaryLoaded({
                data: dictionary.content,
                completeDictionary: dictionary,
              });
            }
            return SfAuthActions.generalErrorModeUpdated({
              generalErrorMode: 'dictionary',
            });
          })
        )
      )
    );
  },
  { functional: true }
);
